@charset "utf-8";

/*=================================
  汎用クラスの定義
=================================*/
body {
  opacity: 0;
  @include animation-base(all,.5s,ease-in-out);
  &.load {
    opacity: 1;
  }
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.inner-wrap-s {
  max-width: $base-width-s;
  margin: 0 auto;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.text--base {
  font-size: 1.5rem;
}
.notes {
  font-size: 1.4rem;
  display: block;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
/*共通レイアウト*/
.sideContWrap {
  @include sideContWrap;
}
.widelink__cover {
  img {
     width: 100%;
  }
}
/*パンくず*/
.breadcrumb {
  padding: 35px 38px 0;
  ul {
    display: flex;
    align-items: center;
    li {
      font-size: 1.4rem;
      line-height: 1;
      color: $font-color-light;
      a {
        font-size: 1.4rem;
        line-height: 1;
        color: $font-color-light;
        text-decoration: underline;
        @include mq-desktop {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    li + li {
      padding-left: 12px;
      &::before {
        content: ">";
        margin-right: 12px;
      }
    }
  }
}
/* =================================
  article common
================================= */
a {
  color: $color-theme;
}
table {
  border-collapse: collapse;
  border: 1px solid #d6dbdc;
  margin: 20px 0 35px;
  width: 100%;
  caption {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 20px 0;
  }
  thead {
    th {
      font-size: 1.6rem;
      color: $color-theme;
      text-align: center;
      background: #f8f9fa;
    }
  }
  th {
    font-size: 1.6rem;
    font-weight: 900;
    color: $color-theme;
    border-bottom: 1px solid #d6dbdc;
    background: #f8f9fa;
    padding: 20px 20px;
    text-align: left;
    min-width: 160px;
  }
  td {
    font-size: 1.6rem;
    color: #222;
    border-bottom: 1px solid #d6dbdc;
    background: #fff;
    padding: 20px 20px;
    width: calc(100% - 160px);
  }
  caption {
    font-size: 1.4rem;
    text-align: left;
  }
}
.yarpp-related {
  margin: 0!important;
}

/*ボタン*/
.btnWrap {
  margin: 28px auto 0;
  text-align: center;
  a {
    font-size: 1.7rem;
    color: #fff;
    background: $color-theme;
    display: inline-block;
    padding: 25px 20px;
    border-radius: 3px;
    min-width: 198px;
  }
}
.btnWrap--2col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px 0 0;
  > * + * {
    margin-left: 20px;
  }
}
.btn--form {
  position: relative;
  display: inline-block;
  border-radius: 3px;
  overflow: hidden;
  input {
    font-size: 1.7rem;
    color: #fff;
    text-align: center;
    padding: 25px 20px;
    cursor: pointer;
    min-width: 198px;
  }
  &.back {
    input {
      background: #afafaf;
    }
  }
  &.next {
    input {
      background: $color-form-red;
    }
  }
  @include mq-desktop {
    max-width: 290px;
    &:hover {
      opacity: .8;
    }
  }
}
.btn--base {
  font-size: 1.7rem;
  color: #fff;
  text-align: center;
  padding: 25px 20px;
  cursor: pointer;
  min-width: 198px;
  background: $color-theme;
  display: inline-block;
  border-radius: 3px;
  @include mq-desktop {
    &:hover {
      color: #fff;
      opacity: .8;
    }
  }
  &.green {
    background: $color-green;
  }
  &.red {
    background: $color-form-red;
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
    base
  ================================= */
  html,body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .inner-wrap {
    width: 100%;
  }
  .inner-wrap-s {
    width: 100%;
  }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .float-l,.float-r {
    float: none;
  } 
  .is-flex{
    display: block;
  }
  .widelink .widelink__cover {
    position: relative;
  }
  /*共通レイアウト*/
  .layout--2col {
    display: block;
    margin: 0 auto 30px;
    &__cont {
      width: 100%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin-left: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin-right: 0;
      }
    }
  }
  /*wrap*/
  .mainWrap {
    &.under {
      padding: 50px 0 0;
    }
  }
  /*パンくず*/
  .breadcrumb {
    padding: 25px 5% 0;
    overflow-x: scroll;
    white-space: nowrap;
    ul {
      li {
        font-size: 1.3rem;
        color: $font-color-light;
        a {
          font-size: 1.3rem;
        }
      }
    }
  }
  /* =================================
    article common
  ================================= */
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0 30px;
    caption {
      font-size: 1.3rem;
      padding: 10px 3%;
      margin: 0;
    }
    th {
      font-size: 1.4rem;
      padding: 10px 15px;
      min-width: 8em;
    }
    td {
      font-size: 1.4rem;
      padding: 10px 15px;
      width: calc(100% - 8em);
    }
  }
  /*ボタン*/
  .btnWrap {
    margin: 20px auto 0;
    a {
      font-size: 1.5rem;
      padding: 15px 5%;
    }
  }
  .btnWrap--2col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0 0;
    > * {
      width: 50%;
      &+ * {
        margin-left: 16px;
      }
    }
  }
  .btnWrap--2col {
    display: block;
    margin: 25px 0 0;
    * {
      width: 100%;
      text-align: center;
    }
    > * + * {
      margin: 10px 0 0;
    }
  }
  .btn--form {
    input {
      font-size: 1.5rem;
      padding: 15px 5%;
    }
  }
  .btn--base {
    font-size: 1.5rem;
    padding: 15px 5%;
  }
}
