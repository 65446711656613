@charset "utf-8";
body {
  word-break: break-all;
  font-feature-settings: "palt";
  background: url(../img/bg.jpg) repeat-y top right;
}
//変数の定義
$base-width: 1022px;
$base-width-s: 1000px;
$side-width: 265px;
$sideCont-width: calc(100% - #{$side-width});
$display-width-s: 1024px;
$base-color: #fff;
$font-color-base: #14191d;
$font-color-light: #181818;
$color-theme: #006899;
$color-theme-dark: #a19354;
$color-red: #fb522c;
$color-orange: #ec7c08;
$color-form-red: #ff2d2d;
$color-green: #00a68d;


@mixin mq-sp {
    @media screen and (min-width: 0) and (max-width: $display-width-s) {
        @content;
    }
}
@mixin mq-mid {
  @media screen and (max-width: 1480px) {
      @content;
  }
}
@mixin mq-desktop {
    @media screen and (min-width: $display-width-s) {
        @content;
    }
}

//フォントの定義
/* Noto Sans JP */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
%font {
  color: $font-color-base;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, Lato, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "palt";
  @include mq-sp{ font-size: 1.5rem; } //::スマホ
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap');
/* Nunito */
%font-round {
  font-family: 'Nunito', sans-serif;
}

//疑似アイコンの定義
@mixin icon-base($width, $height) {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: $width;
  height: $height;
}

//余白の定義
%margin-wrap {
  @include mq-sp{//::スマホ
    margin: 0 8px;
  }
}
%margin-wrap-in {
  margin: 0 24px;
  @include mq-sp{//::スマホ
    margin: 0 12px;
  }
}

@mixin sideContWrap {
  max-width: $sideCont-width;
  margin: 0 100px 0 $side-width;
  box-shadow: 0px 0px 65px 0px rgba(1, 44, 64, 0.3);
  background: #fff;
  @include mq-mid {
    margin: 0 0 0 $side-width;
  }
  @include mq-sp {
    max-width: 100%;
    margin: 0 auto;
  }
}